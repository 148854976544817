<template>
  <v-container>
    <v-row justify="center" v-if="!loading">
      <v-card
        :loading="!this.$store.getters.ownerId.length"
        class="mx-auto"
        max-width="374"
        elevation="0"
      >
        <v-card rounded="xl">
          <v-card-text class="deep-purple--text text--lighten-2">
            <p>Вы собираетесь записаться на:</p>
            <h3>{{ this.$store.getters.date | date }} {{ this.$store.getters.time }}</h3>
            <br>
            Услуга:<b> {{ this.$store.getters.category.title }}</b>
            <br>
            Процедуры:<b> {{ this.$store.getters.services.map(s => s.title).join(", ") }}</b>
            <br>
            Стоимость:<b> {{ this.$store.getters.total }} руб</b>
          </v-card-text>
        </v-card>

        <v-form v-model="valid" ref="form" @submit.prevent="choiceToContinue">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="formFirstName"
                  :rules="firstNameFormRules"
                  color="deep-purple"
                  label="Имя"
                  required
                  prepend-icon="mdi-account"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="formLastName"
                  :rules="lastNameFormRules"
                  color="deep-purple"
                  label="Фамилия"
                  required
                  prepend-icon="mdi-account"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="formPhone"
                  :rules="phoneFormRules"
                  color="deep-purple"
                  label="Номер телефона"
                  required
                  persistent-hint
                  prepend-icon="mdi-phone"
                  :disabled="!!clientAuth"
                  :hint="clientAuth ? 'Вы авторизованы с данным номером телефона, для редактирования нажмите на выход в правом верхнем углу страницы' : ''"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="comment"
                  color="deep-purple"
                  label="Комментарий"
                  persistent-hint
                  hint="необязательное поле"
                  prepend-icon="mdi-comment-processing"
                ></v-text-field>
              </v-col>

              <v-col v-if="!clientAuth" cols="12" sm="12">
                <GoogleRecaptcha/>
              </v-col>

              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="checkbox"
                  :rules="checkboxRules"
                  color="deep-purple lighten-2"
                >
                  <template v-slot:label>
                    <div>
                      Я ознакомился и принимаю условия
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            href="/agreement.pdf"
                            @click.stop
                            v-on="on"
                          >
                            пользовательского соглашения,</a>
                        </template>
                        Перейдите по ссылке чтобы ознакомиться
                      </v-tooltip>
                      а также даю согласие на обработку своих персональных данных
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>

            <v-row justify="start">
              <v-btn
                color="deep-purple lighten-2"
                type="submit"
                :disabled="!this.valid || continueDisabled"
                :dark="this.valid && !continueDisabled"
                elevation="3"
              >
                Продолжить
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card>

      <v-dialog v-model="overlay" persistent dark>
        <v-card>
          <v-container>
            <v-col cols="12" sm="4" class="mx-auto">
              <p>
                В ближайшее время Вам позвонят с неизвестного номера необходимо
                <br>ВЗЯТЬ ТРУБКУ и ПРОСЛУШАТЬ
                <br>проверочный код из 4 цифр
                <br><b style="color: #ff5252">
                <u>не торопитесь отвечать, иначе робот решит, что сработал автоответчик, и сбросит вызов</u></b>
              </p>
            </v-col>
          </v-container>

          <v-container>
            <form @submit.prevent="authorizeClient">
              <v-row justify="center">
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="code"
                    type="number"
                    color="deep-purple lighten-2"
                    label="Введите код XXXX сюда"
                    required
                    :hint="hintText"
                    persistent-hint
                  ></v-text-field>

                  <v-btn
                    v-if="timeout === 0"
                    text
                    color="deep-purple lighten-2"
                    small
                    @click.prevent="repeatCallHandler"
                  >
                    ПОВТОРИТЬ ВЫЗОВ
                  </v-btn>
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-col cols="12" sm="4">
                  <v-btn
                    class="white--text"
                    color="deep-purple"
                    @click.prevent="authorizeClient"
                  >
                    Проверить код
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapState} from "vuex"
import phoneFilter from "../../filters/phoneFilter"
import GoogleRecaptcha from "@/components/GoogleRecaptcha"

export default {
  name: "Introduce",
  components: {GoogleRecaptcha},

  data: () => ({
    loading: true,
    overlay: false,
    zIndex: 0,
    formFirstName: "",
    firstNameFormRules: [
      v => !!v.length || "Заполните это поле",
    ],
    formLastName: "",
    lastNameFormRules: [
      v => !!v.length || "Заполните это поле",
    ],
    formPhone: "",
    phoneFormRules: [
      v => !!v.length || "Заполните это поле",
      v => v.length === 12 || "Номер должен быть в формате +79*********"
    ],
    comment: "",
    checkbox: true,
    checkboxRules: [
      c => !!c || "Для продолжения необходимо принять пользовательское соглашение"
    ],
    valid: true,
    code: "",
    timeout: 60,
    hintText: "Повторный вызов можно будет выполнить через 60",
    agreement: false,
    continueDisabled: false
  }),

  computed: {
    ...mapState(["clientAuth"]),
    ...mapGetters(["ownerId", "firstName", "lastName", "phone"])
  },

  methods: {
    async checkVuexRequiredFields() {
      if (
        !this.$store.getters.category ||
        !this.$store.getters.services.length ||
        !this.$store.getters.date.length ||
        !this.$store.getters.time.length
      ) {
        await this.$router.push("/")
      }
    },

    async checkWins() {
      const id = this.ownerId
      let json = JSON.stringify({id})

      const response = await fetch(`${this.$store.state.server}/record/check-window-list-for-client`, {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: json
      })

      json = await response.json()
      if (json.empty) {
        await this.$router.push("/")
      }
    },

    reset() {
      this.$refs.form.reset()
    },

    async overlayHandler() {
      this.$refs.form.validate()
      if (!this.valid) return

      //eslint-disable-next-line
      const recaptchaToken = grecaptcha.getResponse()
      if (!recaptchaToken) {
        this.$store.commit('setServerMessage', 'Пожалуйста, подтвердите, что Вы не робот')
        return
      }

      this.$store.commit("setFirstName", this.formFirstName)
      this.$store.commit("setLastName", this.formLastName)
      this.$store.commit("setPhone", this.formPhone)

      this.continueDisabled = true
      this.$store.commit("setServerMessage", "Пожалуйста подождите идет проверка капчи")

      const response = await fetch(`${this.$store.state.server}/call/make`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          phone: this.formPhone,
          firstName: this.formFirstName.trim(),
          lastName: this.formLastName.trim(),
          owner: this.$store.getters.ownerId,
          recaptchaToken
        })
      })

      const json = await response.json()
      this.$store.commit("setServerMessage", json.message)

      if (response.status !== 200) {
        return await this.$router.push("/")
      }

      this.overlay = true

      if (this.timeout === 0) this.timeout = 30
      else setInterval(() => {
        if (this.timeout > 0) {
          this.hintText = `Повторный вызов можно будет выполнить через ${this.timeout}`
          this.timeout -= 1
          if (this.timeout === 0) {
            this.hintText = ""
          }
        }
      }, 1000)
    },

    async repeatCallHandler() {
      this.overlay = false
      this.continueDisabled = false
    },

    async checkCodeHandler() {
      if (this.code.toString().length !== 4) {
        return
      }

      let response = await fetch(`${this.$store.state.server}/call/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          phone: this.formPhone,
          code: this.code,
          firstName: this.formFirstName.trim(),
          lastName: this.formLastName.trim(),
          owner: this.ownerId,
        })
      })

      if (response.status === 200) {
        this.$store.commit("setServerMessage", "Код успешно проверен")
        let json = await response.json()
        localStorage.setItem("token", JSON.stringify(json.token))
        localStorage.setItem("phone", JSON.stringify(json.phone))
        localStorage.setItem("firstName", JSON.stringify(json.firstName))
        localStorage.setItem("lastName", JSON.stringify(json.lastName))
      } else {
        let json = await response.json()
        this.$store.commit("setServerMessage", json.message)
      }
    },

    async recordClient() {
      const json = await this.authFetch(`/record/record-client`, {
          date: this.$store.state.currentRecord.date,
          time: this.$store.state.currentRecord.time,
          firstName: this.formFirstName.trim(),
          lastName: this.formLastName.trim(),
          category: this.$store.state.currentRecord.category._id,
          services: this.$store.state.currentRecord.services.map(service => service._id),
          comment: this.comment.trim(),
          total: this.$store.state.currentRecord.total,
          open: this.$store.state.currentRecord.open,
          owner: this.$store.state.currentRecord.ownerId
        },
        false
      )

      if (json.status === 200) {
        this.$store.commit("setTime", "")
        this.$store.commit("setDate", "")
        this.$store.commit("setPhone", "")
        this.$store.commit("setLastName", "")
        this.$store.commit("setFirstName", "")
        this.$store.commit("setTotal", 0)
        this.$store.commit("setServices", [])
        this.$store.commit("setCategory", "")
        await this.$router.push("/client-records")
      }
    },

    async authorizeClient() {
      await this.checkCodeHandler()
      await this.recordClient()
    },

    async checkAuthorization() {
      if (this.clientAuth) {
        this.formFirstName = this.firstName
        this.formLastName = this.lastName
        this.formPhone = this.phone
      }
    },

    choiceToContinue() {
      if (this.$store.state.clientAuth) {
        this.$store.commit('setServerMessage', 'Пожалуйста подождите')
        this.continueDisabled = true
        this.recordClient()
      }
      else this.overlayHandler()
    }
  },

  async mounted() {
    await this.checkVuexRequiredFields()
    await this.checkWins()
    await this.checkAuthorization()
    this.loading = false
  },

  watch: {
    formPhone: function () {
      this.formPhone = phoneFilter(this.formPhone)
    }
  }
}
</script>

<style scoped>

</style>
